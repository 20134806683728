/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import { useAppState, useActions } from "../../presenter";
import Header from "../header";
import Footer from "../footer/Footer";
import { useNavigate } from 'react-router-dom';
import "../../assets/css/checkout.css";
import FilePondUpload from "../admin/adSpace/filePond";
import {useForm} from 'react-hook-form'
const Checkout = () => {
  const { user, cartItems } = useAppState();

  const { requestRent, fetchCart, updateCart } = useActions();
  const navigate = useNavigate();
  const [uploadedFiles, setUploadedFiles] = useState([]);
  const [isInstallment, setIsInstallment] = useState(false);
  const { register, handleSubmit, formState: { errors } } = useForm();
  const [isMonth, setIsMonth] = useState(false);

  const getCart = async () => {
    const response = await fetchCart();
    // if(!response){
    //todo handle error
    // }
    return response;
  };
  const onSubmit =async (data) => {
    console.log("body dayta",data); // You can handle form submission here
    const response = await requestRent({...data,creatives:uploadedFiles});
    console.log("response",response);
    if (response) navigate("/");
  };
  const handleImg = (links) => {
    console.log("handling ", links);
    setUploadedFiles(links);
  };

  // useEffect(() => {
  //   console.log("installment?", isInstallment);

  //   let items = [];
  //   if (user) {
  //     items = getCart();
  //   } else {
  //     console.log("user not logged in");
  //   }
  // }, [user, cartItems]);
  return (
    <div>
      <Header />
      <div className="checkout">
          <div className="card checkout-listings">
        <form onSubmit={handleSubmit(onSubmit)}>
            <h3 className="checkoutTitle"> Billing Information </h3>
            <div className="input-container">
              {isMonth && (
                <div>
                  <label className="input-title"> Number Of Months</label>
                  <input
                    className="input-field "
                    type="number"
                    placeholder="Number Of Months"
                    {...register("numberOfMonths")} 
                  />
                </div>
              )}
              {!isMonth && (
                <div>
                  <label className="input-title">Start Date</label>
                  <input id="startDate" className="date-picker " type="date"{...register("startDate")} />
                </div>
                
              )}
              {isMonth && (
                <div>
                  <b
                    className="link"
                    onClick={() => {
                      setIsMonth(false);
                    }}
                  >
                    Have specific dates?
                  </b>
                </div>
              )}
              {!isMonth && (
                <div>
                  <label className="input-title">
                    End Date
                    <em
                      className="link"
                      onClick={() => {
                        setIsMonth(true);
                      }}
                    >
                      Don't know your dates?
                    </em>
                  </label>
                  <input id="endDate" className="date-picker " type="date"{...register("endDate")} />
                </div>
              )}
              <div>
                <label className="input-title">
                  Commercial Registration Number
                </label>
                <input
                  className="input-field "
                  type="text"
                  placeholder="ex. 123456"
                  {...register("commercialRegistrationNumber")}
                />
              </div>
              <div>
                <label className="input-title">Tax Registration Number</label>
                <input
                  className="input-field "
                  type="text"
                  placeholder="ex. 1000XXXXXXXXXX"
                  {...register("taxRegistrationNumber")}
                />
              </div>
              <div>
                <label className="input-title">Contact Person Number</label>
                <input
                  className="input-field "
                  type="text"
                  placeholder="+20 0123456789"
                  {...register("contactPersonNumber")}
                />
              </div>
              <div>
                <label className="input-title">Upload Creatives</label>

                <FilePondUpload onUpload={handleImg} />
              </div>
            </div>
            <hr />
            <h3 className="checkoutTitle"> Additional Info </h3>

            <label className="input-title">Order Notes</label>
            <textarea className="note-area" {...register("orderNotes")}></textarea>
        </form>
          </div>
        <div className="card order-summary">
          <h3 className="checkoutTitle"> Order Summary </h3>
          {/* <hr />
          <h3 className="checkoutTitle"> Payment Methods </h3> */}
          {/* // todo should be a drop down */}
          <div className="summary-line"></div>
          <div className="items-spacing">
              <span className='bold-word'>Subtotal </span> <span className='light-word'> {calculateTotalPrice(cartItems)}</span>
          </div>
          <div className="custom-line"></div>
          <div className="items-spacing">
              <span className='bold-word'>Spaces Added</span><span className='light-word'> {cartItems.reduce((total, item) => total + item.quantity, 0)} items</span>
          </div>
          <div className="custom-line"></div>
          <div className="items-spacing">
              <span className='extra-bold-word'>Total </span><span className='light-word'> {calculateTotalPrice(cartItems)}</span>
          </div>
         
          <div className="form-check">
            <input
              className="form-check-input"
              type="checkbox"
              value=""
              id="flexCheckDefault"
              onClick={(e) => {
                setIsInstallment(e.target.checked);
              }}
            />
            <label className="form-check-label" htmlFor="flexCheckDefault">
              Pay in Installments
            </label>
          </div>
          <button className="btn update-view-btn" type="submit" onClick={handleSubmit(onSubmit)}>Place Order</button>
        </div>
      </div>
      <Footer />
    </div>
  );
};
const calculateTotalPrice = (cartItems) => {
  return cartItems.reduce((total, item) => total + item.adsId.price.$numberDecimal * item.quantity, 0);
};
export default Checkout;
