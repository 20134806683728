/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from 'react';
import { useLocation,useSearchParams,Link } from 'react-router-dom';
// import { Range } from 'react-range';
import { useActions, useAppState } from '../../presenter';
import {Accordion} from 'react-bootstrap';
import Header from '../header';
import GridView from './gridView';
import ListView from './listView';
import MultiDropDown from './MultiDropDown';
import SortDropDown from './SortDropDown';
import { useNavigate } from 'react-router';
import PageLoader from './pageLoader';
import MapView from './mapView';
import Footer from '../footer/Footer';
import '../../assets/css/spaces.css'

const AdvertisingSpaces = () => {
  //todo use searchParams
  const [searchParams, setSearchParams] = useSearchParams();
  const navigate = useNavigate();
  const location = useLocation();
  const [filteredAdvertisingSpaces, setFilteredAdvertisingSpaces] = useState(null);
  const [categories, setCategories] = useState([]);
  const [categoriesFilter, setCategoriesFilter] = useState([]);
  // const [selectedCategory, setSelectedCategory] = useState("");
  // const [selectedCategoryIndex, setSelectedCategoryIndex] = useState(null);
  const [checkedItems, setCheckedItems] = useState([]);
  const [activeTab, setActiveTab] = useState("grid");
  const { getListings, getCategories, fetchCart, addToCart, updateCart, deleteAd } = useActions();
  const [filters, setFilters] = useState({});
  const [isClear, setIsClear] = useState(false);
  
  const [totalPages, setTotalPages] = useState(1);
  const [sortType, setSortType] = useState(-1);
  const [sortBy, setSortBy] = useState("updatedAt");
  
  const [showing, setShowing] = useState(0);
  const [totalDocs, setTotalDocs] = useState(0);
  const [currentPage, setCurrentPage] = useState(1);
  const [hasNextPage, setHasNextPage] = useState(null);
  const [hasPrevPage, setHasPrevPage] = useState(null);
  
  const { user, cartItems } = useAppState();

  const replacementMap = {
    'BillBoard': 'Billboard',
    'DigitalScreen': 'Digital Screens',
    'TransitMedia': 'Transit Media',
    'PlaceBased': 'Place-Based Media',
    'Street': 'Street',
  };
  const processCategories = () => {
  if (checkedItems.length === 0) {
    return JSON.stringify(categoriesFilter);
  }

  const arr = [];

  checkedItems.forEach((subcategory) => {
    categories.forEach((category) => {
      if (category.subcategories.includes(subcategory)) {
        const categoryName = Object.keys(replacementMap).find((key) => replacementMap[key] === category.name);
        if (categoryName && !arr.includes(categoryName)) {
          arr.push(categoryName);
        }
      }
    });
  });

  return arr.length ? JSON.stringify(arr) : JSON.stringify(categoriesFilter);
};
  const updateWindowUrl= ()=>{
    const queryParams = new URLSearchParams(location.search);
    // todo check if not null before setting
    if(sortType !==null && sortType !==undefined &&sortType){
      queryParams.set("sortType", sortType);
    }
    if(sortBy !==null && sortBy !==undefined &&sortBy){
    queryParams.set("sortBy", sortBy);
    }
    if(categories !==null && categories !==undefined &&categories){
      queryParams.set("categories", categories);
    }
    if(filters !==null && filters !==undefined &&filters){
      queryParams.set("filters", JSON.stringify(filters));
    }
    if(currentPage !==null && currentPage !==undefined &&currentPage){
      queryParams.set("page", currentPage);
    }
    setSearchParams(queryParams)
  }
  useEffect(() => {
    if (!categories || categories.length === 0) {
      fetchCategories();
    }
    const queryParams = new URLSearchParams(location.search);
    const searchQuery = queryParams.get("q");
    const pageFromURL = parseInt(queryParams.get("page")) || currentPage;
    const queryFilters = queryParams.get("filters");
    const filtersFromURL = queryFilters ? queryFilters : "";
    const subcategoriesFromURL = queryParams.get("subcategories")
      ? JSON.parse(queryParams.get("subcategories"))
      : [];
    // const categoriesFromURL = queryParams.get('categories') ? JSON.parse(queryParams.get('categories')) : [];
    const sortByParams = queryParams.get("sortBy") ?? "updatedAt";
    const sortTypeParams = queryParams.get("sortType")
      ? parseInt(queryParams.get("sortType"))
      : -1;

    // Create the object with default values
    const fetchDataParams = {
      filters: filtersFromURL,
      page: pageFromURL,
      sortBy: sortByParams,
      sortType: sortTypeParams,
    };

    // Conditionally include searchQuery if it is truthy
    if (searchQuery) {
      fetchDataParams.q = searchQuery;
    }

    if (sortBy !== sortByParams || sortType !== sortTypeParams) {
      console.log("use effect 1", sortTypeParams);
      fetchData(fetchDataParams);
    } else {
      fetchData({ ...fetchDataParams, sortBy, sortType });
    }
    setSortBy(sortByParams);
    setSortType(sortTypeParams);
    setCheckedItems(subcategoriesFromURL);
    if (filtersFromURL && filtersFromURL.length < 3) {
      const deserialized = JSON.parse(filtersFromURL);
      setFilters(deserialized);
      setIsClear(true);
    }
    // setCategoriesFilter(categoriesFromURL);
    // Update state only if necessary
    if (pageFromURL !== currentPage) {
      setCurrentPage(pageFromURL);
    }
  }, [user, location.search]);

  // useEffect(() => {
  //   if (currentPage){
  //     console.log('useEffect 2')
  //     fetchData();
  //   }
  // }, [currentPage]);

  // useEffect(() => {
  //   console.log('cartItems', cartItems);
  //   handleSetList(filteredAdvertisingSpaces);
  // }, [cartItems]);

  const handleSetList = (arr, items) => {
    // console.log('arr', arr);
    setFilteredAdvertisingSpaces(null);
    const updatedSpaces = arr?.map((space) => {
      const cartItem = items.find((item) => item._id === space.id);
      if (cartItem) {
        return { ...space, quantity: cartItem.quantity };
      }
      return space;
    });
    setFilteredAdvertisingSpaces(updatedSpaces);
  };

  const updateView = () => {
    console.log("current filters",filters);
      let obj={}
      //category check
      if (checkedItems.length > 0) {
        const arr = [];
        obj.subcategories = JSON.stringify(checkedItems);
        checkedItems.map((subcategory) => {
          categories.map(category => {
            if (category.subcategories.includes(subcategory) && !arr.includes(Object.keys(replacementMap).find((key) => replacementMap[key] === category.name))) arr.push(Object.keys(replacementMap).find((key) => replacementMap[key] === category.name))
          })
        })
        obj.categories = arr.length ? JSON.stringify(arr) : JSON.stringify(categoriesFilter);
      }
      //copy pagination and sort
      obj.page=currentPage;
      obj.sortBy=sortBy;
      obj.sortType=sortType;
      //convert filters to url
      obj.filters=JSON.stringify(filters);
      // obj.filters=serializeFilters(filters);
      console.log("myobj",obj);
      updateWindowUrl()
    fetchData(obj);
    
  };

  const handlePageChange = (page) => {
    setCurrentPage(page);
    updateWindowUrl();
  };

  const handlePageChangeNext = () => {
    const nextPage = currentPage + 1;
    setCurrentPage(nextPage);
    updateWindowUrl();
  };

  const handlePageChangePrev = () => {
    const prevPage = currentPage - 1;
    setCurrentPage(prevPage);
    updateWindowUrl();
  };

  const serializeFilters = (filters) => {
    console.log("to be serialized",filters);
    // Serialize the entire filters object as a JSON string
    const filtersString = JSON.stringify(filters);
    // Encode the JSON string
    const encodedFilters = encodeURIComponent(filtersString);
    return encodedFilters;
};


  const fetchData = async (obj={page:1,sortType:-1,sortBy:'updatedAt'}) => {
    // todo must have an object with default values
    try {
      let items = [];
      if (user) {
        items = await fetchCart();
        console.log("the response",items);
      }
      console.log("sent obj",obj);
      const response = await getListings(obj);
      console.log("API RESPONSE",response);
      // calculate currently shown
      const startIndex = response.pagingCounter - 1;
      const endIndex = Math.min(startIndex + response.limit - 1, response.totalDocs - 1);
      const currently = endIndex - startIndex + 1;
      setShowing(currently);
      setTotalDocs(response.totalDocs);
      setTotalPages(response.totalPages);
      setHasNextPage(response.hasNextPage);
      setHasPrevPage(response.hasPrevPage);
      handleSetList(response.docs, items[0] ? items[0].items : []);
    } catch (error) {
      console.error("error fetching",error);
    }
  };
  const sortCategories = (list)=>{
    // console.log("the list",list);
    const customOrder = ['BillBoard', 'DigitalScreen', 'TransitMedia', 'PlaceBased','Street'];
    list.sort((a, b) => customOrder.indexOf(a.name) - customOrder.indexOf(b.name));
  
    // Update the name property of each item using the mapping
    list = list.map((item) => {
      const newName = replacementMap[item.name] || item.name; // Use replacement or original name
      item.name = newName;
      return item;
    });
    return list;
  }
  const fetchCategories = async () => {
    try {
      const response = await getCategories();
      // order here 
      // console.log("response ", response);
      const orderedCategories = sortCategories(response);
      // console.log("ordered response",orderedCategories);
      setCategories(orderedCategories);
    } catch (error) {
      console.error(error);
    }
  };

  const handleFilterChange = (values, filterName) => {
    setFilters(prevFilters => ({
      ...prevFilters,
      [filterName]: values,
    }));
    console.log("filters",filters);
    setIsClear(true);
  };

  // const handleClassFilterChange = (event) => {
  //   const selectedClass = event.target.value;
  //   const isChecked = event.target.checked;

  //   // Check if the selected class is already in the filters.classes array
  //   const index = filters.classes.indexOf(selectedClass);

  //   if (isChecked && index === -1) {
  //     // Add the selected class to filters.classes if it's not already present
  //     handleFilterChange([...filters.classes, selectedClass], 'classes');
  //   } else if (!isChecked && index !== -1) {
  //     // Remove the selected class from filters.classes if it's present
  //     const updatedClasses = filters.classes.filter((cls) => cls !== selectedClass);
  //     handleFilterChange(updatedClasses, 'classes');
  //   }
  // };

  // const handleCategoryFilter = async (category) => {
  //   setSelectedCategory(category);
  //   const response = await getListingsByCategory({ filters: JSON.stringify(filters), category: category.id });
  //   handleSetList(response);
  // };

  // Function to handle checkbox change
  const handleCheckboxChange = (subcategory) => {
    // Check if the subcategory is already in the checkedItems array
    const isChecked = checkedItems.includes(subcategory);

    // Update the checkedItems state based on checkbox status
    if (isChecked) {
      // If the subcategory is already checked, remove it from the array
      setCheckedItems(checkedItems.filter((item) => item !== subcategory));
    } else {
      // If the subcategory is not checked, add it to the array
      setCheckedItems([...checkedItems, subcategory]);
    }
  };
  const handleCityFilterChange = (val) =>{
    console.log("changed city",val);
    handleFilterChange(val,'location');
  }
  const handleQuantityChange = async (id, value,price) => {
    if (!user) {
      navigate("/login");
      return;
    }
    setFilteredAdvertisingSpaces((prevSpaces) =>
      prevSpaces?.map((space) =>
        space.id === id ? { ...space, quantity: (space.quantity ? space.quantity : 0) + value } : space
      )
    );
    const cartItem = cartItems.find(item => item._id === id);
    if (cartItem) {
      await updateCart({ adId: cartItem._id, quantity: cartItem.quantity + value });
    }
    else await addToCart({ adId: id, quantity: value,price});

    fetchCart();
  };

  const handleDeleteAd = async (id) => {
    await deleteAd(id);
    fetchData();
  };

  // const handleRemoveFromCart = async (itemId) => {
  //   try {
  //     await removeFromCart(itemId);
  //     fetchCart();
  //   } catch (error) {
  //     // Handle error
  //     console.error('Error removing item from cart:', error);
  //   }
  // };

  return (
    <>
      <Header activeMenu={"Marketplace"} />

       

      <div className="container" style={{maxWidth:'1200px', marginTop:'170px', paddingLeft:'0px' , paddingRight:'0px'}}>
        <div className="d-flex">
          <div className="filters-col col-md-3">
            <div className="card  col-md-3">
              <div className="filters-body">
                <h2 className="categories-title">All Categories</h2>
                <div className="separator-line-two"></div>
                <Accordion alwaysOpen>
                  {categories?.map((category, index) => (
                    <Accordion.Item eventKey={index} key={category.name}>
                      <Accordion.Header>{category.name}</Accordion.Header>
                      <Accordion.Body>
                        {category.subcategories?.map((subcategory, j) => (
                          <div className="form-check" key={subcategory}>
                            <input className="form-check-input" type="checkbox" id={subcategory}
                              checked={checkedItems.includes(subcategory)}
                              onChange={() => handleCheckboxChange(subcategory)} />
                            <label className="form-check-label subcategory-item" htmlFor={subcategory}>
                              {subcategory} (0)
                            </label>
                          </div>
                        ))}
                      </Accordion.Body>
                    </Accordion.Item>
                  ))}
                </Accordion>
                  {/* adspace reach */}
                {/* <div className="mb-3">
                  <label htmlFor="priceRange" className="form-label">Reach</label>
                  <div className='d-flex'>
                    <p className='mr-8'>Between</p>
                    <input className='price-input mr-8' type="number" placeholder='100 e.g' onKeyUp={e => handleFilterChange(+e.target.value, 'reachStart')} />
                    <p className='mr-8'>and</p>
                    <input className='price-input' type="number" placeholder='1000 e.g' onKeyUp={e => handleFilterChange(+e.target.value, 'reachEnd')} />
                  </div>
                  <div className="filter-separator"></div>
                </div> */}

                <div className="mb-3">
                  <label htmlFor="priceRange" className="form-label">Location</label>
                  <div style={{ display: "grid",marginBottom:'30px' }}>
                    <MultiDropDown onChange={handleCityFilterChange} />
                  </div>
                  <div className="filter-separator"></div>
                </div>

                <div className="mb-3">
                  <label htmlFor="priceRange" className="form-label">Price</label>
                  <div className='d-flex'>
                    <p className='mr-8'>From</p>
                    <input className='price-input mr-8' type="number" placeholder='100 e.g' onKeyUp={e => handleFilterChange(+e.target.value, 'priceStart')} />
                    <p className='mr-8'>to</p>
                    <input className='price-input' type="number" placeholder='1000 e.g' onKeyUp={e => handleFilterChange(+e.target.value, 'priceEnd')} />
                  </div>
                </div>

                {/* <div className="mb-3">
                  <label htmlFor="sides" className="form-label">Sides</label>
                  <Range
                    id="sides"
                    min={1}
                    max={42}
                    step={1}
                    values={filters.sides}
                    onChange={values => handleFilterChange(values, 'sides')}
                    renderTrack={({ props, children }) => (
                      <div
                        {...props}
                        style={{
                          ...props.style,
                          height: '6px',
                          width: '100%',
                          background: '#ddd',
                        }}
                      >
                        {children}
                      </div>
                    )}
                    renderThumb={({ props }) => (
                      <div
                        {...props}
                        style={{
                          ...props.style,
                          height: '16px',
                          width: '16px',
                          borderRadius: '50%',
                          background: '#888',
                          cursor: 'pointer',
                        }}
                      />
                    )}
                  />
                </div> */}

                {/* <div className="mb-3">
                  <label htmlFor="reachRange" className="form-label">Reach</label>
                  <Range
                    id="reachRange"
                    min={10000}
                    max={100000000}
                    step={100}
                    values={filters.reachRange}
                    onChange={values => handleFilterChange(values, 'reachRange')}
                    renderTrack={({ props, children }) => (
                      <div
                        {...props}
                        style={{
                          ...props.style,
                          height: '6px',
                          width: '100%',
                          background: '#ddd',
                        }}
                      >
                        {children}
                      </div>
                    )}
                    renderThumb={({ props }) => (
                      <div
                        {...props}
                        style={{
                          ...props.style,
                          height: '16px',
                          width: '16px',
                          borderRadius: '50%',
                          background: '#888',
                          cursor: 'pointer',
                        }}
                      />
                    )}
                  />
                </div> */}

                {/* <div className="mb-3">
                  <label className="form-label">Class:</label>
                  <div className="form-check">
                    <input
                      id="classA"
                      className="form-check-input"
                      type="checkbox"
                      name="classes"
                      value="Class A"
                      checked={filters.classes.includes('Class A')}
                      onChange={handleClassFilterChange}
                    />
                    <label htmlFor="classA" className="form-check-label">Class A</label>
                  </div>
                  <div className="form-check">
                    <input
                      id="classB"
                      className="form-check-input"
                      type="checkbox"
                      name="classes"
                      value="Class B"
                      checked={filters.classes.includes('Class B')}
                      onChange={handleClassFilterChange}
                    />
                    <label htmlFor="classB" className="form-check-label">Class B</label>
                  </div>
                  <div className="form-check">
                    <input
                      id="classC"
                      className="form-check-input"
                      type="checkbox"
                      name="classes"
                      value="Class C"
                      checked={filters.classes.includes('Class C')}
                      onChange={handleClassFilterChange}
                    />
                    <label htmlFor="classC" className="form-check-label">Class C</label>
                  </div>
                  <div className="form-check">
                    <input
                      id="other"
                      className="form-check-input"
                      type="checkbox"
                      name="classes"
                      value="Other"
                      checked={filters.classes.includes('Other')}
                      onChange={handleClassFilterChange}
                    />
                    <label htmlFor="other" className="form-check-label">Other</label>
                  </div>
                </div> */}

                <button type="button" className="btn update-view-btn" onClick={updateView}>Update View</button>   
                {isClear && <Link className="clearBtn" to='/' onClick={()=>setIsClear(false)}>
                   Clear Selection
                </Link>}
              </div>
            </div>
          </div>

          <div className="listing-col col-md-9">
            <div className="view-container col-md-12 d-flex align-items-center p-4">
              <div className="btn-group">
                <button onClick={() => { setActiveTab("grid") }} type="button" className={"btn view-btn " + (activeTab === "grid" ? "view-btn-active" : "")}>
                  <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" fill={activeTab === "grid" ? "#fff" : "black"} className="bi bi-grid-fill" viewBox="0 0 16 16">
                    <path d="M1 2.5A1.5 1.5 0 0 1 2.5 1h3A1.5 1.5 0 0 1 7 2.5v3A1.5 1.5 0 0 1 5.5 7h-3A1.5 1.5 0 0 1 1 5.5v-3zm8 0A1.5 1.5 0 0 1 10.5 1h3A1.5 1.5 0 0 1 15 2.5v3A1.5 1.5 0 0 1 13.5 7h-3A1.5 1.5 0 0 1 9 5.5v-3zm-8 8A1.5 1.5 0 0 1 2.5 9h3A1.5 1.5 0 0 1 7 10.5v3A1.5 1.5 0 0 1 5.5 15h-3A1.5 1.5 0 0 1 1 13.5v-3zm8 0A1.5 1.5 0 0 1 10.5 9h3a1.5 1.5 0 0 1 1.5 1.5v3a1.5 1.5 0 0 1-1.5 1.5h-3A1.5 1.5 0 0 1 9 13.5v-3z" />
                  </svg>
                </button>
                <button onClick={() => { setActiveTab("list") }} type="button" className={"btn view-btn " + (activeTab === "list" ? "view-btn-active" : "")}>
                  <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" fill={activeTab === "list" ? "#fff" : "black"} className="bi bi-list" viewBox="0 0 16 16">
                    <path fillRule="evenodd" d="M2.5 12a.5.5 0 0 1 .5-.5h10a.5.5 0 0 1 0 1H3a.5.5 0 0 1-.5-.5zm0-4a.5.5 0 0 1 .5-.5h10a.5.5 0 0 1 0 1H3a.5.5 0 0 1-.5-.5zm0-4a.5.5 0 0 1 .5-.5h10a.5.5 0 0 1 0 1H3a.5.5 0 0 1-.5-.5z" />
                  </svg>
                </button>
                <button onClick={() => { setActiveTab("map") }} type="button" className={"btn view-btn " + (activeTab === "map" ? "view-btn-active" : "")}>
                  <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" fill={activeTab === "map" ? "#fff" : "black"} className="bi bi-geo-alt-fill" viewBox="0 0 16 16">
                    <path d="M8 16s6-5.686 6-10A6 6 0 0 0 2 6c0 4.314 6 10 6 10zm0-7a3 3 0 1 1 0-6 3 3 0 0 1 0 6z" />
                  </svg>
                </button>
              </div>
            <SortDropDown />
            </div>
            <div className="countSpan">
              {/* <span > Showing {showing} out of {totalDocs} </span> */}
            </div>
            {activeTab === "grid" && (
              filteredAdvertisingSpaces ? (
                filteredAdvertisingSpaces.length ?
                <GridView
                  filteredAdvertisingSpaces={filteredAdvertisingSpaces}
                  onClick={handleQuantityChange}
                  onDelete={handleDeleteAd}
                />
                : <p>No advertising spaces found.</p>
              ) : (
                <div className='d-flex justify-content-center mb-4'>
              <PageLoader />
              </div>
              )
            ) }

            {activeTab === "list" &&
              <ListView filteredAdvertisingSpaces={filteredAdvertisingSpaces} onClick={handleQuantityChange} onDelete={handleDeleteAd} />
            }
            {activeTab === 'map' && 
              <MapView filteredAdvertisingSpaces={filteredAdvertisingSpaces} />}
            
          </div>
        </div >
      </div >
      {activeTab !== 'map' && 
            <nav aria-label="Page navigation example" className='d-flex justify-content-center pagination'>
              <ul className="pagination">
                <li className={`page-item ${!hasPrevPage ? 'disabled' : ''}`}
                  onClick={() => handlePageChangePrev()}>
                  <a className="page-link" href="#" aria-label="Previous">
                    <span aria-hidden="true">&laquo;</span>
                  </a>
                </li>

                {totalPages > 0 && Array.from({ length: totalPages }, (_, index) => index + 1).map((page,index) => (
                  <li key={index} className={`page-item ${page === currentPage ? 'active' : ''}`}
                    onClick={() => handlePageChange(page)}
                  ><a className="page-link" href="#">{page}</a></li>
                ))}

                <li className={`page-item ${!hasNextPage ? 'disabled' : ''}`}
                  onClick={() => handlePageChangeNext()}>
                  <a className="page-link" href="#" aria-label="Next">
                    <span aria-hidden="true">&raquo;</span>
                  </a>
                </li>
              </ul>
            </nav>
            }
      <Footer/>
    </>
  );
};

export default AdvertisingSpaces;
